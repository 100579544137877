import React from "react";
import { sections } from "../sectionConstants";
import { MobileNavigation } from "./MobileNavigation";
import { scrollIntoView } from "../../utils/scrollIntoView";

type Props = {};

const NavbarOptions = [
  {
    label: "Explore Plans",
    href: sections.plans,
  },
  {
    label: "Our Story",
    href: sections.story,
  },
  {
    label: "Testimonials",
    href: sections.testimonials,
  },
  /* {
    label: "Careers",
    href: sections.careers,
  }, */
  {
    label: "Contact Us",
    href: sections.contact,
  },
  {
    label: "Healthcare Providers",
    href: sections.providers,
    onlyMobile: true,
  },
  {
    label: "Español",
  },
];
export const Navbar: React.FC<Props> = () => {
  return (
    <div className="navbar flex w-100 items-center justify-between py-[20px]">
      <div className="md:mr-[40px] lg:mr-[100px]">
        <img src="/svg/logo-blue.svg" alt="logo" className="logo" />
      </div>
      <div className="hidden md:flex max-w-[600px] justify-between w-full font-[500]">
        {NavbarOptions.map(({ label, href, onlyMobile }) =>
          onlyMobile ? (
            <></>
          ) : (
            <div
              key={label}
              className="cursor-pointer border-b-[2px] border-b-transparent hover:border-b-blue-100"
              onClick={scrollIntoView(href)}
            >
              {label}
            </div>
          )
        )}
      </div>
      <MobileNavigation options={NavbarOptions} />
    </div>
  );
};
