import { MailchimpFormContainer } from "../../components/form/MailchimpFormContainer";
import { sections } from "../sectionConstants";
import "./Contact.css";

export const Contact = () => {
  return (
    <div
      className="bg-light-gray py-[100px] px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]"
      id={sections.contact}
    >
      <div className="contact-container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 bg-blue-900">
          <div className="w-full relative contact-image-filter">
            <img
              className="img-full"
              src="/img/contact/contact-image.png"
              alt="woman stretching outdoors"
            />
            <div className="absolute h-full flex items-center justify-center text-fontWhite z-10 top-0 bottom-0 left-0 right-0 text-center">
              <div className="max-w-md md:max-w-[500px]">
                <h2 className="text-3xl md:text-4xl">
                  Take the first step toward better coverage.
                </h2>
                <p className="text-xl mt-2">
                  Join <span className="font-[700] text-blue-100">5,000+</span>{" "}
                  happy clients!
                </p>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex items-center justify-center">
            <div className="p-5 w-full max-w-xl flex items-center justify-center">
              <MailchimpFormContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
