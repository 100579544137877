import React from "react";
import { sections } from "../sectionConstants";
import { scrollIntoView } from "../../utils/scrollIntoView";

type Props = {};

const navOptions = [
  {
    label: "Explore Plans",
    href: sections.plans,
  },
  {
    label: "Our Story",
    href: sections.story,
  },
  {
    label: "Testimonials",
    href: sections.testimonials,
  },
  /* {
    label: "Careers",
    href: sections.careers,
  }, */
  {
    label: "Healthcare carriers",
    href: sections.providers,
  },
];

const socialMedia = [
  {
    icon: "/svg/footer/mail.svg",
    href: "mailto:info@mightyshieldinsurance.com",
  },
  {
    icon: "/svg/footer/whatsapp.svg",
    href: "https://wa.me/18329292268",
  },
  {
    icon: "/svg/footer/instagram.svg",
    href: "https://instagram.com/mymightyshield?igshid=MTIzZWMxMTBkOA==",
  },
  {
    icon: "/svg/footer/facebook.svg",
    href: "https://www.facebook.com/profile.php?id=100092508933495&mibextid=LQQJ4d",
  },
  {
    icon: "/svg/footer/linkedin.svg",
    href: "https://www.linkedin.com/company/mighty-shield-insurance/",
  },
];

export const Footer: React.FC<Props> = () => {
  return (
    <div>
      <div className="bg-blue-100 w-full h-[12px]" />
      <div className="bg-blue-900 pt-[60px] pb-[100px] px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px] flex flex-wrap justify-between">
        <div className="mr-5 mb-[30px] md:mb-0">
          <img
            src="/svg/logo-white.svg"
            alt="logo"
            className="h-[60px] w-auto"
          />
          <span className="text-[16px] mt-[15px] text-fontWhite block">
            info@mightyshieldinsurance.com
          </span>
        </div>
        <div className="text-fontWhite flex flex-col min-w-[200px] mr-5 mb-[30px] md:mb-0">
          <span className="text-[20px] font-[500] mb-[15px]">
            Mighty Shield
          </span>
          {navOptions.map(({ label, href }) => (
            <span
              className="mb-[13px] text-[16px] cursor-pointer"
              onClick={scrollIntoView(href)}
            >
              {label}
            </span>
          ))}
        </div>
        <div className="text-fontWhite flex flex-col min-w-[200px] mr-5 mb-[30px] md:mb-0">
          <span className="text-[20px] font-[500] mb-[15px]">Contact</span>
          <span className="mb-[13px] text-[16px]">
            Houston Office:{" "}
            <a href="tel:+18322058213" className="text-blue-100">
              832-205-8213
            </a>
          </span>
          <span className="mb-[13px] text-[16px]">
            San Antonio Office:{" "}
            <a href="tel:+12102014778" className="text-blue-100">
              210-201-4778
            </a>
          </span>
          <span className="mb-[13px] text-[16px]">
            McAllen Office:{" "}
            <a href="tel:+19562307727" className="text-blue-100">
              956-230-7727
            </a>
          </span>
          <span className="mb-[13px] text-[16px]">
            Florida Office:{" "}
            <a href="tel:+18329292268" className="text-blue-100">
              832-929-2268
            </a>
          </span>
        </div>
        <div className="text-fontWhite flex flex-col min-w-[200px] mr-5 mb-[30px] md:mb-0">
          <span className="text-[20px] font-[500] mb-[15px]">
            Let’s keep in touch
          </span>
          <div className="flex gap-[25px]">
            {socialMedia.map(({ icon, href }) => (
              <a href={href} target="_blank" rel="noreferrer">
                <img src={icon} alt="social-media-logo" />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-dark-blue py-[27px] px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px] flex flex-col md:flex-row justify-between">
        <div className="max-w-[280px] flex flex-col md:flex-row justify-between w-full mb-[30px] md:mb-0">
          <span className="text-fontWhite mb-[14px] md:mb-0">
            Terms & Conditions{" "}
          </span>
          <span className="text-fontWhite mb-[14px] md:mb-0">
            Privacy Policy
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-fontWhite mb-[14px] md:mb-0">
            © Copyright 2023. All Rights Reserved
          </span>
          <span className="text-fontWhite mb-[14px] md:mb-0 text-[13px]">
            Website by Chromo Design
          </span>
        </div>
      </div>
    </div>
  );
};
