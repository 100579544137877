import { HomePage } from "./home";
/* import { Home } from "./homePage";
import { Thanks } from "./thanksPage"; */

export const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "*",
    element: <HomePage />,
  },
];
