import React, { useState } from "react";
import "./OurPlans.css";
import { sections } from "../sectionConstants";
import { ModalForm } from "../../components/Modal/ModalForm";

type Props = {};

const plans = [
  {
    icon: "/svg/ourPlans/health.svg",
    title: "Health Insurance",
    content:
      "Our comprehensive health insurance covers everything from preventive check-ups to hospital stays, offering options to fit every budget and lifestyle.",
  },
  {
    icon: "/svg/ourPlans/life.svg",
    title: "Life Insurance",
    content:
      "Secure your family's financial well-being. Our life insurance offers a financial buffer for your loved ones when it's needed most.",
  },
  {
    icon: "/svg/ourPlans/travel.svg",
    title: "Travel Insurance",
    content:
      "Travel without worry. Our travel insurance handles unexpected issues, ensuring your journey continues smoothly.",
  },
  {
    icon: "/svg/ourPlans/dental-vision.svg",
    title: "Dental & Vision ",
    content:
      "Keep your smile bright and your vision clear. Our dental and vision plans deliver affordable, quality oral and eye health care.",
  },
  {
    icon: "/svg/ourPlans/property.svg",
    title: "Property & Casualty",
    content:
      "Shield your assets from sudden events. Our property and casualty insurance offers financial aid when it matters most.",
  },
  {
    icon: "svg/ourPlans/medicare.svg",
    title: "Medicare",
    content:
      "Quality healthcare for seniors. Our Medicare plans offer affordable access to essential services, sans financial strain.",
  },
];

export const OurPlans: React.FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [planSelected, setPlanSelected] = useState<string>("");

  const handleOpenModal = (plan: string) => () => {
    setIsModalOpen(true);
    setPlanSelected(plan);
  };

  const handleOnCLose = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="bg-gray pt-[60px] pb-[100px] flex flex-col items-center px-[20px] md:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]"
      id={sections.plans}
    >
      <h2 className="mb-[10px]">Explore Our Plans</h2>
      <span className="secondary-text">
        Find an affordable healthcare paln tailored to your needs.
      </span>
      <div className="grid-container">
        {plans.map(({ icon, title, content }) => (
          <div className="card">
            <div>
              <img src={icon} alt="plan-icon" className="w-auto h-[70px]" />
              <div className="text-[22px] md:text-[26px] font-[700] mt-[26px]">
                {title}
              </div>
              <div className="text-[16px] mt-[26px]">{content}</div>
            </div>
            <div
              className="flex text-[18px] md:text-[20px] font-[500] text-blue-100 cursor-pointer items-center"
              onClick={handleOpenModal(title)}
            >
              <span className="mr-[8px]">Learn More</span>
              <img
                src="./svg/arrow-right-small.svg"
                alt="arrow-right"
                className="text-blue-100"
              />
            </div>
          </div>
        ))}
      </div>
      <ModalForm
        open={isModalOpen}
        planSelected={planSelected}
        onClose={handleOnCLose}
      />
    </div>
  );
};
