import React, { useState } from "react";
import "./MobileNavigation.css";

interface Props {
  options: { label: string; href?: string }[];
}
export const MobileNavigation: React.FC<Props> = ({ options }) => {
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const toggleMenuMobile = () => {
    setOpenMenuMobile(!openMenuMobile);
  };

  return (
    <div className="md:hidden">
      <img
        src="/svg/btn-burger.svg"
        className="btn-burger cursor-pointer"
        alt="btn-burger"
        width={28}
        onClick={toggleMenuMobile}
      />
      <div
        className={`cont-main ${
          !openMenuMobile && "transition-entrance"
        } bg-blue-900 p-[18px]`}
      >
        <div className="py-3 px-2 flex flex-col justify-between">
          <div className="flex justify-between mb-[30px]">
            <span className="text-blue-100 text-[18px] font-[700]">Menu</span>
            <img
              src="/svg/close-button.svg"
              alt="close-button"
              onClick={toggleMenuMobile}
              className="cursor-pointer"
            />
          </div>
          <ul>
            {options.map(({ label, href }) => (
              <li
                onClick={toggleMenuMobile}
                key={href}
                className="item-list border-b-[2px] border-blue-100 mb-[20px] w-fit pb-1"
              >
                <a
                  className="text-fontWhite font-[500] text-[16px]"
                  href={`#${href}`}
                >
                  <span>{label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
