import React from "react";
import { sections } from "../sectionConstants";
import "./OurStory.css";

type Props = {};

export const OurStory: React.FC<Props> = () => {
  return (
    <div
      className="pt-[36 px] pb-[100px] flex flex-col tb:flex-row items-center px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px] our-story-container"
      id={sections.story}
    >
      <div className="flex flex-col mr-0 tb:mr-[50px] mt-[30px] md:mt-[100px] mb-[250px] md:mb-0 items-start text-center md:text-left">
        <h2 className="mb-[16px] md:mb-[32px] text-fontWhite mx-auto md:mx-0">
          Our Story
        </h2>
        <div className="flex flex-col max-w-[600px] mb-[20px] tb:mb-[30px] px-[10px] md:px-0">
          <span className="text-[14px] md:text-[20px] text-fontWhite">
            Born from the complexity of insurance, Mighty Shield Insurance
            emerged as a beacon of clarity and assurance.
            <br />
            <br />
            Our mission is simple: Make insurance straightforward and personal,
            ensuring you get optimal coverage at the right price. Today, Mighty
            Shield Insurance is a rapidly growing agency committed to
            safeguarding your peace of mind and security while providing
            exceptional customer service to our clients.
            <br />
            <br />
            We navigate the insurance maze with you so you're ready for whatever
            life throws your way.{" "}
            <strong className="text-blue-100">
              That's our story, and we're here to help protect yours.
            </strong>
          </span>
        </div>
      </div>
      <div></div>
    </div>
  );
};
