import { InputHTMLAttributes } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import "./Input.css";
import { getErrorMessage } from "../../../utils/data-transform";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegisterReturn<string>;
}

export const Input = ({
  label,
  placeholder,
  register,
  errors,
  type,
  ...rest
}: Props) => {
  const errorMessage = getErrorMessage(register?.name || "", errors);

  return (
    <div>
      <label htmlFor={register?.name} className="text-fontWhite font-bold">
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        className={`Input w-full ${errorMessage ? "invalid-input" : ""} `}
        {...register}
        {...rest}
      />
      {errorMessage && (
        <span className="invalid-feedback">{`${errorMessage}`}</span>
      )}
    </div>
  );
};
