import { SelectHTMLAttributes } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import "./Select.css";
import { getErrorMessage } from "../../../utils/data-transform";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegisterReturn<string>;
  options: {
    value: string;
    label: string;
    isDisabled?: boolean
    isDefault?: boolean
  }[];
}

export const Select = ({
  label,
  options,
  placeholder,
  register,
  errors,
  ...rest
}: Props) => {
  const errorMessage = getErrorMessage(register?.name || "", errors);

  return (
    <div>
      <label htmlFor={register?.name} className="text-fontWhite font-bold">
        {label}
      </label>
      <select
        value={undefined}
        placeholder={placeholder}
        className={`Select w-full ${errorMessage ? "invalid-input" : ""} `}
        {...register}
        {...rest}
      >
        {options.map((option, key) => (
          <option 
            key={key} 
            value={option.value} 
            disabled={option.isDisabled} 
            data-default={option.isDefault}
          >
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage && (
        <span className="invalid-feedback">{`${errorMessage}`}</span>
      )}
    </div>
  );
};
