export const plansOptions = [
  {
    value: "",
    label: "Select an option",
    isDefault: true,
  },
  {
    value: "Health Insurance",
    label: "Health Insurance",
  },
  {
    value: "Life Insurance",
    label: "Life Insurance",
  },
  {
    value: "Travel Insurance",
    label: "Travel Insurance",
  },
  {
    value: "Dental & Vision ",
    label: "Dental & Vision ",
  },
  {
    value: "Property & Casualty",
    label: "Property & Casualty",
  },
  {
    value: "Medicare",
    label: "Medicare",
  },
  {
    value: "Other",
    label: "Other",
  },
]