import React from "react";
import "./Hero.css";
import { Navbar } from "../Navbar";
import { Button } from "../../components/Button";
import { sections } from "../sectionConstants";

type Props = {};

export const Hero: React.FC<Props> = () => {
  return (
    <div className="h-[100vh] hero px-[20px] md:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]">
      <Navbar />
      <div className="mt-[10px] md:mt-[15vh] flex items-center justify-center md:justify-start">
        <div className="flex flex-col">
          <article className="text-center md:text-start">
            <div className="text-[38px] leading-[50px] sm:text-[60px] md:text-[70px] md:leading-[73px] relative z-[2]">
              <span className="font-bold">Your peace of mind</span>
              <br />
              is not expensive.
              <br />
              <span className="font-bold relative">
                It’s priceless!
                <img
                  src="./svg/blue-line.svg"
                  alt="blue-line"
                  className="absolute right-[-15px] bottom-[2px] z-[-1] w-[232px] md:w-[305px] md:w-[359px]"
                />
              </span>
            </div>
            <span className="block text-[16px] sm:text-[18px] md:text-[20px] mt-[30px] max-w-[300px] mx-auto md:mx-0 md:max-w-[560px]">
              We help you find affordable insurance plans tailored to your
              needs.
              <span className="block md:inline mb-[10px]"> </span>
              Book your <strong> free consultation today!</strong>
            </span>

            <Button
              className="mt-[30px] md:w-[150px]"
              label="Learn More!"
              href={sections.plans}
            />
          </article>
        </div>
      </div>
    </div>
  );
};
