export type Testimonial = {
  img: string
  name: string
  content: string
}

export const testimonials: Testimonial[] = [
  {
    img: '/img/testimonials/Juan-Garcia.png',
    name: 'Juan Garcia',
    content: "As a loyal customer of Mighty Shield Insurance for several years, their service quality is impeccable. Their support team is responsive and professional, always ready to assist. Strongly recommend their services."
  },
  {
    img: '/img/testimonials/Maria-Rodriguez.png',
    name: 'Maria Rodriguez',
    content: "From the moment I contacted them for a quote, their agents were friendly, patient, and willing to address all my concerns. They took the time to explain the various coverage options available and helped me customize a policy that suited my needs perfectly."
  },
  {
    img: '/img/testimonials/Carlos-Martinez.png',
    name: 'Carlos Martinez',
    content: "My experience with Mighty Shield proves their trustworthiness. Their dedication to customer satisfaction and personalized policy recommendations show their commitment to their clients."
  },
  {
    img: '/img/testimonials/Emily-Thompson.png',
    name: 'Emily Thompson',
    content: "Mighty Shield's customer support has been nothing short of extraordinary. They've been patient, friendly, and readily available to address my concerns, making my insurance journey easy."
  },
  {
    img: '/img/testimonials/Rosa-Hernandez.png',
    name: 'Rosa Hernandez',
    content: "I recently switched to Mighty Shield for my property insurance. They offer competitive rates without compromising coverage and have a range of policy options fitting my needs and budget."
  },
  {
    img: '/img/testimonials/David-Smith.png',
    name: 'David Smith',
    content: "As a small business owner, I relied on my agent to find affordable health insurance for my team. The service from the provider has been incredibly responsive, and my employees are happy with their coverage."
  },
  {
    img: '/img/testimonials/Elizabeth-Ramirez.png',
    name: 'Elizabeth Ramirez',
    content: "When we were expecting our first child, our agent helped us navigate our health insurance queries. My plan covered all prenatal and delivery expenses, making our journey worry-free."
  },
  {
    img: '/img/testimonials/Enrique-Fernandez.png',
    name: 'Enrique Fernandez',
    content: "Switching to Mighty Shield has been a game-changer. My agent took the time to understand my needs, helping me find a plan that offers better coverage at lower costs."
  },
]