import React from "react";
import { Hero } from "./Hero";
import { Statistics } from "./Statistics";
import { OurPlans } from "./OurPlans";
import { OurStory } from "./OurStory";
import { Providers } from "./Providers";
import { Testimonials } from "./Testimonials";
import { Footer } from "./Footer";
import { Contact } from "./Contact";

type Props = {};

export const HomePage: React.FC<Props> = () => {
  return (
    <div>
      <Hero />
      <Statistics />
      <OurPlans />
      <OurStory />
      <Providers />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
};
