import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Testimonial } from "../../home/Testimonials/TestimonialsData";
import { TestimonialCard } from "../Testimonials/Card/TestimonialCard";
import { transformIntoGroups } from "../../utils/data-transform";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Slider.css";

const breakpointLimitMap = {
  md: {
    breakpoint: 992,
    slidesToShow: 1,
  },
  lg: {
    breakpoint: 1200,
    slidesToShow: 4,
  },
};

const calculateGroupsBasedOnScreenWidth = (width: number) => {
  if (width <= breakpointLimitMap.md.breakpoint) {
    return breakpointLimitMap.md.slidesToShow;
  } else {
    return breakpointLimitMap.lg.slidesToShow;
  }
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export const TestimonialSlider = ({ slides }: { slides: Testimonial[] }) => {
  const sliderRef = useRef<any>(null);
  const [slidesGrouped, setSlidesGrouped] = useState(
    transformIntoGroups(
      slides,
      calculateGroupsBasedOnScreenWidth(window.innerWidth)
    )
  );

  useEffect(() => {
    const resetGroups = () => {
      setSlidesGrouped(
        transformIntoGroups(
          slides,
          calculateGroupsBasedOnScreenWidth(window.innerWidth)
        )
      );
    };

    window.addEventListener("resize", resetGroups);

    return () => {
      window.removeEventListener("resize", resetGroups);
    };
  }, [slides]);

  const gotoNext = () => {
    sliderRef?.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef?.current.slickPrev();
  };

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {slidesGrouped.map((slideGroup, index) => (
          <div>
            <div
              key={index}
              className="grid grid-cols-1 lg:grid-cols-2 gap-[40px] p-4"
            >
              {slideGroup.map((testimonial, key) => (
                <TestimonialCard key={key} testimonial={testimonial} />
              ))}
            </div>
          </div>
        ))}
      </Slider>
      <div className="mt-7 flex justify-center gap-[40px]">
        <button onClick={gotoPrev}>
          <img
            src="/svg/arrow-right.svg"
            alt="arrow"
            className="w-[60px] rotate-180"
          />
        </button>
        <button onClick={gotoNext}>
          <img src="/svg/arrow-right.svg" alt="arrow" className="w-[60px]" />
        </button>
      </div>
    </>
  );
};
