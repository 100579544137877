import React from "react";

type Props = {};

const statisticsOptions = [
  {
    title: "$7,500",
    subTitle: "What fixing a leg fracture could cost.",
  },
  {
    divider: true,
  },
  {
    title: "$30,000",
    subTitle: "Average cost of a 3-day hospital stay.",
  },
  {
    divider: true,
  },
  {
    title: "$18,865",
    subTitle: "Average cost of childbirth without insurance.",
  },
  {
    divider: true,
  },
  {
    title: "65.5%",
    subTitle: "% of bankruptcies caused by medical expenses.",
  },
];

export const Statistics: React.FC<Props> = () => {
  return (
    <div className="bg-blue-900 pt-[80px] pb-[100px] flex flex-col items-center px-[20px] md:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]">
      <h2 className="text-fontWhite mb-[16px] md:mb-[100px] leading-[40px]">
        Are you willing to take the risk? Think{" "}
        <span className="text-blue-100 font-[700]">twice.</span>
      </h2>
      <div className="flex flex-col md:flex-row justify-between  items-center md:items-stretch w-full">
        {statisticsOptions.map(({ title, subTitle, divider }) =>
          divider ? (
            <div className="w-[120px] h-[1px] h-unset md:mx-3 md:w-[1px] bg-blue-100"></div>
          ) : (
            <div className="flex flex-col items-center max-w-[290px] md:max-w-[270px] my-[30px] md:my-0">
              <span className="text-[40px] mdtext-[46px] text-blue-100 font-[700] mb-[20px]">
                {title}
              </span>
              <span className="text-[16px] md:text-[20px] text-fontWhite text-center">
                {subTitle}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
