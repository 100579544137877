import { FieldErrors, FieldValues } from "react-hook-form";

export function transformIntoGroups<T>(array: T[], groupLimit: number): T[][] {
  const result: T[][] = [];
  let group: T[] = [];
  
  for (const item of array) {
    group.push(item);
    
    if (group.length === groupLimit) {
      result.push(group);
      group = [];
    }
  }
  
  if (group.length > 0) {
    result.push(group);
  }
  
  return result;
}

export const getErrorMessage = (
	name: string,
	errors?: FieldErrors<FieldValues>,
) => {
	let error = errors ? errors[name] : null

	if (!error && name.includes('.')) {
		const keys = name.split('.')
		error = keys.reduce(
			(acc, key) => acc && (acc[key] as unknown as FieldErrors<FieldValues>),
			errors
		)
	}

	return error?.message || null
}