import React from "react";
import { MailchimpFormContainer } from "../form/MailchimpFormContainer";
interface Props {
  open: boolean | undefined;
  onClose: () => void;
  planSelected?: string;
}

export const ModalForm: React.FC<Props> = ({ open, onClose, planSelected }) => {
  if (!open) {
    return null;
  }
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-dark-900/40"
        onClick={onClose}
      >
        <div className="relative m-auto max-w-md bg-blue-900">
          <div
            className="flex justify-end mt-5 mx-5 cursor-pointer"
            onClick={onClose}
          >
            <img src="./svg/close.svg" alt="" />
          </div>
          <div className="p-10 px-9">
            <MailchimpFormContainer planSelected={planSelected} />
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
