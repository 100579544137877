import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ContactUs } from "./ContactUs";

const MAILCHIMP_BASE_URL =
  "https://mightyshieldinsurance.us11.list-manage.com/subscribe/post";
const MAILCHIMP_U = "cd448461a163945adbb615b52";
const MAILCHIMP_ID = "f0649312b2";
const MAILCHIMP_POST_URL = `${MAILCHIMP_BASE_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;

export const MailchimpFormContainer = ({
  planSelected,
}: {
  planSelected?: string;
}) => (
  <MailchimpSubscribe
    url={MAILCHIMP_POST_URL}
    render={({ subscribe, status, message }) => (
      <ContactUs
        status={status}
        message={message}
        planSelected={planSelected}
        onValidated={(formData: any) => subscribe(formData)}
      />
    )}
  />
);
