export const providers = [
  {
    src: '/img/providers/aetna.png',
    alt: 'aetna-provider',
  },
  {
    src: '/img/providers/united-Health.png',
    alt: 'united-Health-provider',
  },
  {
    src: '/img/providers/ambetter.png',
    alt: 'ambetter-provider',
  },
  {
    src: '/img/providers/WellCare.png',
    alt: 'WellCare-provider',
  },
  {
    src: '/img/providers/Humana.png',
    alt: 'humana-provider',
  },
  {
    src: '/img/providers/Amerigroup.png',
    alt: 'Amerigroup-provider',
  },
  {
    src: '/img/providers/oscar.png',
    alt: 'oscar-provider',
  },
  {
    src: '/img/providers/friday.png',
    alt: 'friday-provider',
  },
  {
    src: '/img/providers/cigna.png',
    alt: 'cigna-provider',
  },
  {
    src: '/img/providers/avMed.png',
    alt: 'avMed-provider',
  },
  {
    src: '/img/providers/molina.png',
    alt: 'molina-provider',
  },
  {
    src: '/img/providers/alliant.png',
    alt: 'alliant-provider',
  },
  {
    src: '/img/providers/ameriHealth.png',
    alt: 'ameriHealth-provider',
  },
  {
    src: '/img/providers/care-source.png',
    alt: 'care-source-provider',
  },
  {
    src: '/img/providers/ascencio.png',
    alt: 'ascencio-provider',
  },
];