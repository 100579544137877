import React from "react";
import { providers } from "./providersData";
import "./Providers.css";
import { sections } from "../sectionConstants";

type Props = {};

export const Providers: React.FC<Props> = () => {
  return (
    <div
      className="bg-light-gray pt-[60px] pb-[100px] px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]"
      id={sections.providers}
    >
      <h2 className="mb-[60px]">Our Trusted Carriers</h2>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-[30px] md:gap-[50px] max-w-[90%] lg:max-w-[1070px] m-auto">
        {providers.map(({ src, alt }) => (
          <img src={src} alt={alt} className="provider-img" />
        ))}
      </div>
    </div>
  );
};
