import { Testimonial } from "../../../home/Testimonials/TestimonialsData";
import "./Testimonials.css";

export const TestimonialCard = ({
  testimonial: { img, name, content },
}: {
  testimonial: Testimonial;
}) => {
  return (
    <div className="comment">
      <div className="flex items-center mb-[20px]">
        <img
          src={img}
          alt="testimonial-img"
          className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mr-[16px]"
        />
        <span className="text-[16px] md:text-[20px] font-[500]">{name}</span>
      </div>
      <span className="text-[14px] md:text-[16px]">"{content}"</span>
    </div>
  );
};
