import React from "react";
import { testimonials } from "./TestimonialsData";
import { sections } from "../sectionConstants";
import { TestimonialSlider } from "../../components/Slider/TestimonialSlider";
type Props = {};

export const Testimonials: React.FC<Props> = () => {
  return (
    <div
      className="bg-gray pt-[60px] pb-[100px] px-[20px] tb:px-[40px] tb:px-[60px] lg:px-[80] xl:px-[120px]"
      id={sections.testimonials}
    >
      <div className="flex flex-col items-center">
        <h2 className="mb-[16px] leading-[40px]">
          We don't do the talking. Our clients do.
        </h2>
        <span className="secondary-text">
          Read what our community is saying.
        </span>
      </div>
      <div className="max-w-[716px] tb:max-w-[1040px] m-auto">
        <TestimonialSlider slides={testimonials} />
      </div>
    </div>
  );
};
