import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Input } from "../form-elements/Input/Input";
import { Button } from "../Button";
import { EMAIL_REGEX, NUMBER_REGEX } from "../../utils/regex";
import { Select } from "../form-elements/Select/Select";
import { plansOptions } from "./constants";

interface FormValues extends FieldValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  plan: string;
}

export const ContactUs = ({
  status,
  message,
  planSelected = "",
  onValidated,
}: {
  status: "error" | "success" | "sending" | null;
  message: string | Error | null;
  planSelected?: string;
  onValidated: (data: {
    MERGE0: string; // Email
    MERGE2: string; // First Name
    MERGE1: string; // Last Name
    MERGE3: string; // Phone
    MERGE4: string; // Plan
  }) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      plan: planSelected,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    onValidated({
      MERGE0: data.email,
      MERGE1: data.last_name,
      MERGE2: data.first_name,
      MERGE3: data.phone,
      MERGE4: data.plan,
    });
  };

  return (
    <>
      {isSubmitSuccessful ? (
        <div className="text-fontWhite font-bold text-center min-h-[400px]">
          <h2 className="text-4xl">Thank you for contacting us!</h2>
          <p className="mt-5 text-lg">We will reach back to you shortly.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {status === "error" && (
            <div className="text-danger mb-4">
              <p>{`${message}`}</p>
            </div>
          )}
          <div className="md:flex md:gap-9">
            <div className="mb-7">
              <Input
                register={register("first_name", {
                  required: "This field is required",
                })}
                errors={errors}
                placeholder="First Name"
                label="First Name"
              />
            </div>
            <div className="mb-7">
              <Input
                register={register("last_name", {
                  required: "This field is required",
                })}
                errors={errors}
                placeholder="Last Name"
                label="Last Name"
              />
            </div>
          </div>
          <div className="mb-7">
            <Input
              register={register("email", {
                required: "This field is required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "This value is not a valid email",
                },
              })}
              type="email"
              errors={errors}
              placeholder="yourname@youremail.com"
              label="Email"
            />
          </div>
          <div className="mb-7">
            <Input
              register={register("phone", {
                required: "This field is required",
                pattern: {
                  value: NUMBER_REGEX,
                  message: "This value is not a phone number",
                },
              })}
              type="tel"
              errors={errors}
              placeholder="Phone"
              label="Phone"
            />
          </div>
          <div className="mb-7">
            <Select
              placeholder="Please select one"
              register={register("plan", {
                required: "This field is required",
              })}
              errors={errors}
              label="What plan are you interested in?"
              options={plansOptions}
            />
          </div>
          <Button
            type="submit"
            className="mt-[30px] w-full md:w-[260px]"
            label="Book FREE Consultation"
            disabled={isSubmitting || status === "sending"}
          />
        </form>
      )}
    </>
  );
};
