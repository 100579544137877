import React, { ButtonHTMLAttributes } from "react";
import { scrollIntoView } from "../../utils/scrollIntoView";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  className: string;
  href?: string;
}

export const Button: React.FC<Props> = ({ label, className, href = "#" }) => {
  return (
    <button
      className={`px-[15px] py-[10px] bg-blue-100 font-[500] text-[16px] text-white rounded ${className}`}
      onClick={scrollIntoView(href)}
    >
      {label}
    </button>
  );
};
